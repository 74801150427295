//import styles
import "./scss/app.scss"

/**
 * import jQuery scripts
 */
try {
    window.$ = window.jQuery = require('jquery')
} catch (e) {}


require('./js/jquery/slick-slider')
require('./js/jquery/slick-slider-settings')
require('./js/jquery/main-search')
require('./js/jquery/ambulances')
require('./js/jquery/map')
require('./js/jquery/contact')
require('./js/jquery/swimming-menu')
require('./js/jquery/navigation-button')
require('./js/jquery/fill-job-position')