$(document).ready( function() {
    $(".intro-slider").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    arrows: false,
                    dots: true,
                }
            }
        ]
    })

    $(".fast-info--slider").slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 861,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    })
})