function ambulancesHomepage() {
    $("#show-all-ambulances").on("click", function() {
        $(this).parents(".ambulances-content--footer").siblings(".ambulances-content--list").find(".ambulance").show();
        $(this).parents(".ambulances-content--footer").hide();
    })

    $(".ambulance-filter-button").on("click", function() {
        $(this).addClass("filter-button-active").siblings().removeClass("filter-button-active");

        if ( $(this).hasClass("ambulance-filter-button-all") ) {

            $(this).parents(".ambulances-content--header").siblings(".ambulances-content--list").find(".ambulance").show();
            
        } else {
            
            $(this).parents(".ambulances-content").find('.ambulance').hide().siblings(`.ambulance[data-category="${$(this).data('category')}"]`).show();
            
        }

        $(this).parents(".ambulances-content--header").siblings(".ambulances-content--footer").hide();
    })
}

$(document).ready( function() {
    ambulancesHomepage();
})