function swimmingMenu() {
    let position = $(window).scrollTop()
    const nav = $(".main-navigation-wrapper")
    const bar = $(".main-header")

    $(window).on("scroll", function() {
        let scroll = $(window).scrollTop();

        if ( scroll > position ) {

            if ( scroll > bar.height() ) nav.addClass("fixed hidden")

        } else {

            if ( !nav.hasClass("fixed") ) nav.addClass("fixed")
            
            if ( scroll < bar.height() * 1.1 ) nav.removeClass("fixed")

            if ( nav.hasClass("hidden") ) nav.removeClass("hidden")

        }

        position = scroll;
    })
}

swimmingMenu()