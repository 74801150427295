function animateMenuIcon() {
    const btn = $( ".nav-btn" )

    btn.on("click", function() {
        $(this).toggleClass("open")
        $("#header-navigation").toggleClass("open-navigation")
    })

    $(window).on("scroll", function() {
        if ( $("#header-navigation").hasClass("open-navigation") ) $("#header-navigation").removeClass("open-navigation")
    })
}

$(document).ready( function() {
    animateMenuIcon()
})