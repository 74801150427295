function contactMap( lat, lng, id ) {
    let options = {
        center: {
            lat: lat,
            lng: lng
        },
        zoom: 18,
    };

    // New map
    map = new google.maps.Map(document.getElementById(id), options);

    // Create marker function
    function addMaker(location) {

        const marker = new google.maps.Marker({
            position: location,
            map: map,
        })
    }

    // New marker
    addMaker({ lat: lat, lng: lng })
}

$(document).ready( function() {
    setTimeout( function() {
        
        $(".contact-content").each( function() {
            contactMap( 1 * $(this).data("lat"), 1 * $(this).data("lng"), $(this).data("map") )
        })

    }, 300)
})
