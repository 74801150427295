function contactNavigation() {
    $(".contact-navigation--box").on("click", function() {
        $(this)
            .addClass("active")
            .siblings(".contact-navigation--box")
            .removeClass("active")

        $(".contact--body--content")
            .find(".contact-content")
            .eq( $(this).data("key") )
            .show()
            .siblings(".contact-content")
            .hide()
    })
}

$(document).ready( function() {
    contactNavigation()
})
