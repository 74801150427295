function fillJobPosition() {
    let input = $("#job-offer")

    if ( input.length ) {
        input.val( $("#sub-header").find(".row").text().trim() )
    }
}

$(document).ready( function() {
    fillJobPosition()
})